/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Miguel Arenas
  Author URL: http://github.com/mxaxaxbx
==========================================================================================*/
import store from "../../../store/store.js";
import auth from "../../../auth/authService.js";

export default [
  // Dashboard
  {
    url: "/app/dashboard",
    name: "Inicio",
    slug: "home",
    icon: "HomeIcon",
    i18n: "Home",
    show: true,
  },
  // Profile
  {
    url: "/app/profile",
    name: "Perfil",
    slug: "profile",
    icon: "UserWhiteIcon",
    i18n: "Profile",
    show: true,
  },
  // Stores
  {
    url: "/app/stores",
    name: "Sellers",
    slug: "store",
    icon: "StoreIcon",
    i18n: "Stores",
    show: auth.isValidModule("MANAGE_SHOPS"),
  },
  // agreements
  {
    url: "/app/applications",
    name: "Aliados",
    slug: "applications-list",
    icon: "BusinessIcon",
    i18n: "Allies list",
    show: auth.isValidModule("OWNER"),
  },
  // users
  {
    url: null,
    name: "Usuarios",
    slug: "users",
    icon: "UsersIcon",
    i18n: "Users",
    show: auth.isValidModule("MANAGE_USERS, READ_USERS"),
    submenu: [
      {
        url: "/app/users/list",
        name: "Administrativos",
        slug: "user admin list",
        i18n: "Users Admin list",
        tagColor: "warning",
      },
      {
        url: "/app/clients/list",
        name: "Clientes",
        slug: "clients list",
        i18n: "Clients list",
        tagColor: "warning",
      },
      {
        url: "/app/consumers/list",
        name: "Consumidores",
        slug: "consumers list",
        i18n: "Consumers list",
        tagColor: "warning",
      },
    ],
  },
  // users
  /* {
    url: "/app/clients/list",
    name: "Tiendas",
    slug: "tiendas",
    icon: "UsersIcon",
    i18n: "Tiendas",
    show: auth.isValidModule("OWNER, MANAGE_USERS"),
  }, */
  // products
  {
    url: "/app/products",
    name: "Productos",
    slug: "products",
    icon: "ProductsIcon",
    i18n: "Products",
    show: auth.isValidModule("MANAGE_PRODUCTS, READ_PRODUCTS"),
  },
  // inventory
  /* {
    url: "/app/inventory/list",
    name: "Inventario",
    slug: "inventory",
    icon: "ListIcon",
    i18n: "Inventory",
    show: auth.isValidModule("MANAGE_INVENTORIES, READ_INVENTORIES"),
  }, */
  {
    url: "/app/preorders/list",
    name: "Pre Ordenes",
    slug: "Pre Ordenes",
    icon: "TagIcon",
    i18n: "Pre Ordenes",
    show: auth.isValidModule("MANAGE_INVENTORIES, READ_INVENTORIES"),
  },
  // promotions
  {
    url: null,
    name: "Promociones",
    slug: "Promotions",
    icon: "PercentIcon",
    i18n: "Promotions",
    show: auth.isValidModule("MANAGE_PROMOTION, READ_PROMOTION"),
    submenu: [
      {
        url: "/app/promotions",
        name: "Lista de Promociones",
        slug: "promotions list",
        i18n: "Promotions list",
        tagColor: "warning",
      },
      {
        url: "/app/promotions/rules",
        name: "Lista de Reglas",
        slug: "rules list",
        i18n: "Rules list",
        tagColor: "warning",
      },
      {
        url: "/app/promotions/banners",
        name: "Banners",
        slug: "banners",
        i18n: "Promotions banners",
        tagColor: "warning",
      },
    ],
  },
  // orders
  {
    url: null,
    name: "Ordenes",
    icon: "ShoppingCartIcon",
    i18n: "Orders",
    show: auth.isValidModule("MANAGE_ORDERS, READ_ORDERS"),
    submenu: [
      {
        url: "/app/orders/in-progress",
        name: "En Proceso",
        slug: "in-progress",
        i18n: "InProgress",
        tag:
          store.state.tracking.newOrders.length > 0
            ? store.state.tracking.newOrders.length.toString()
            : null,
        tagColor: "warning",
      },
      {
        url: "/app/orders",
        name: "Histórico",
        slug: "orders-history",
        i18n: "OrdersHistory",
      },
    ],
  },
  // leads
  /* {
    url: "/app/products/leads",
    name: "Productos lead",
    slug: "products lead",
    icon: "ShoppingBagIcon",
    i18n: "Lead admin",
    show: auth.isValidModule( 'MANAGE_PRODUCTS, READ_PRODUCTS' ),
  }, */
  // indicators
  /* {
    url: "/app/indicators",
    name: "Indicadores",
    slug: "indicators",
    icon: "BarsIcon",
    i18n: "Indicators",
    show: auth.isValidModule( 'MANAGE_INDICATOR, READ_INDICATOR' ),
  }, */
  // customers
  /* {
    url: "/app/customers/support",
    name: "CustomerSupport",
    slug: "customer-support",
    icon: "HeadphoneIcon",
    i18n: "CustomerSupport",
    show: auth.isValidModule( 'MANAGE_CALLCENTER, CREATE_CALLCENTER' ),
  }, */
  // doctors
  /* {
    url: "/app/doctors",
    name: "Doctors",
    slug: "doctors",
    icon: "DatabaseIcon",
    i18n: "Doctors",
    show: auth.isValidModule( 'OWNER' ),
  }, */
  // coupons
  {
    url: "/app/coupons/list",
    name: "Cupones",
    slug: "coupons",
    icon: "TagIcon",
    i18n: "coupons",
    show: auth.isValidModule("OWNER"),
  },
  // push notifications
  {
    url: "/app/push",
    name: "Notificaciones",
    slug: "push",
    icon: "BellIcon",
    i18n: "push notifications",
    show: auth.isValidModule("OWNER, MANAGE_MESSAGES"),
  },
  // logout
  /* {
    url: "/app/logout",
    name: "Cerrar sesión",
    slug: "logout",
    icon: "LogoutIcon",
    i18n: "Logout",
    show: true,
  }, */
];
