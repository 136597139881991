<!-- =========================================================================================
  File Name: TheNavbarVertical.vue
  Description: Navbar component
  Component Name: TheNavbarVertical
  ----------------------------------------------------------------------------------------
  Item Name: Sitidoctor - plantilla administrativa - aliado
  Author: Miguel A. Arenas
  Author URL: http://gitgub.com/mxaxaxbx
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="{'vs-navbar-tr': navbarColorLocal == 'transparent'}">

        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar"></feather-icon>

        <template v-if="windowWidth >= 992">
          <!-- WELLCOME MESSAGE -->
          <div class="ml-4">
            <h2 ref="pageTitle" @click="playNotification()">{{ $t(pageTitle.title) }}</h2>
            <p> <span>{{ $t(pageTitle.subtitle) }}</span> </p>
          </div>

          <!-- STARRED PAGES - FIRST 10 -->
          <!-- <ul class="vx-navbar__starred-pages">
            <draggable v-model="starredPagesLimited" :group="{name: 'pinList'}" class="flex cursor-move">
              <li class="starred-page" v-for="page in starredPagesLimited" v-bind:key="page.url">
                <vx-tooltip :text="page.label" position="bottom" delay=".3s">
                  <feather-icon svgClasses="h-6 w-6" class="p-2 cursor-pointer" :icon="page.labelIcon" @click="$router.push(page.url).catch(() => {})" />
                </vx-tooltip>
              </li>
            </draggable>
          </ul> -->

          <!-- STARRED PAGES MORE -->
          <!-- <div class="vx-navbar__starred-pages--more-dropdown" v-if="starredPagesMore.length">
            <vs-dropdown vs-custom-content vs-trigger-click>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" class="cursor-pointer p-2"></feather-icon>
              <vs-dropdown-menu>
                <ul class="vx-navbar__starred-pages-more--list">
                  <draggable v-model="starredPagesMore" :group="{name: 'pinList'}" class="cursor-move">
                    <li class="starred-page--more flex items-center cursor-pointer" v-for="page in starredPagesMore" v-bind:key="page.url" @click="$router.push(page.url).catch(() => {})">
                      <feather-icon svgClasses="h-5 w-5" class="ml-2 mr-1" :icon="page.labelIcon"></feather-icon>
                      <span class="px-2 pt-2 pb-1">{{ page.label }}</span>
                    </li>
                  </draggable>
                </ul>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div> -->

          <!-- <div class="bookmark-container">
            <feather-icon icon="StarIcon"
              :svgClasses="
                [
                  'stoke-current text-warning',
                  { 'text-white': starredPageIconColor }
                ]"
                class="cursor-pointer p-2"
                @click.stop="showBookmarkPagesDropdown = !showBookmarkPagesDropdown"
              />
                      <div v-click-outside="outside" class="absolute bookmark-list w-1/3 xl:w-1/4 mt-4" v-if="showBookmarkPagesDropdown">
            <vx-auto-suggest :autoFocus="true" :data="navbarSearchAndPinList" @selected="selected" @actionClicked="actionClicked" inputClassses="w-full" show-action show-pinned background-overlay></vx-auto-suggest>
            </div>
          </div> -->
        </template>

        <vs-spacer />

        <!-- SEARCHBAR -->
        <!-- <div class="search-full-container w-full h-full absolute left-0" :class="{'flex': showFullSearch}" v-show="showFullSearch">
          <vx-auto-suggest
            v-if="activeUser"
            class="w-full"
            inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
            :autoFocus="showFullSearch"
            :data="navbarSearchAndPinList"
            icon="SearchIcon"
            placeholder="Search..."
            ref="navbarSearch"
            @closeSearchbar="showFullSearch = false"
            @selected="selected"
            background-overlay
          />
            
          <div class="absolute right-0 h-full z-50">
            <feather-icon icon="XIcon" class="px-4 cursor-pointer h-full close-search-icon" @click="showFullSearch = false"></feather-icon>
          </div>
        </div>
        
        <div
          class="
            lg:w-1/3
            lg:border lg:border-solid
            rounded-full
            p-1 pt-2 pl-4
            cursor-text
          "
          @click="showFullSearch = true"
        >
          <feather-icon icon="SearchIcon" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search"></feather-icon>
        </div> -->

        <!-- I18N -->
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
          <span class="cursor-pointer flex items-center i18n-locale">
            <img class="h-4 w-5" :src="i18n_locale_img" :alt="$i18n.locale" />
            <span class="hidden sm:block ml-2">{{ languaje_name }}</span>
          </span>
          <vs-dropdown-menu class="w-48 i18n-dropdown vx-navbar-dropdown">
            <vs-dropdown-item @click="updateLocale('pt', 'Portuguese')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/br.png" alt="pt" /> &nbsp;Portuguese</vs-dropdown-item>
            <vs-dropdown-item @click="updateLocale('en', 'English')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en" /> &nbsp;English</vs-dropdown-item>
            <vs-dropdown-item @click="updateLocale('es', 'Español')"><img class="h-4 w-5 mr-1" src="@/assets/images/flags/es.png" alt="fr" /> &nbsp;Español</vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
        

        
        <!-- NOTIFICATIONS -->
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer ml-4" @focus="changeNotificationsToReaded()">
          <vs-button radius color="white" text-color="#666" >
            <feather-icon icon="BellIcon" class="cursor-pointer " :badge="unreadNotifications"></feather-icon>
          </vs-button>
          
          <vs-dropdown-menu style="width:420px;" class="notification-dropdown dropdown-custom vx-navbar-dropdown">
            <div class="notification-top pt-5 pl-5 pb-5 pr-5">
              <h4 class="float-left"> {{ $t('Notificaciones') | capitalize }} </h4>
              <vs-button v-if="notifications.length > 0" class="float-right rounded-full" type="border" size="small" color="#666" icon-pack="feather" icon="icon-x" @click="deleteNotifications()">
                {{ $t('Borrar todas') }}
              </vs-button>
            </div>
            
            <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--nofications-dropdown">
              <div v-if="notifications.length === 0" class="p-5 text-center">
                {{ $t('No tienes notificaciones') }}
              </div>
              <ul class="bordered-items">
                <li v-for="ntf in notifications" v-bind:key="ntf.index" class="flex justify-between px-4 py-4 notification cursor-pointer">
                  <a :href="`/app/notifications/${ntf.id}`" class="flex items-start">
                      <!-- <feather-icon :icon="ntf.icon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon> -->
                      <img :src="require(`@/assets/icons/${ntf.icon}.svg`)" width="32px" />
                      
                      <div class="mx-2 text-dark">
                        <!-- <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{ ntf.title }}</span> -->
                        <span class="font-medium block notification-title">{{ $t(ntf.title) }}</span>
                        <small> {{ $t(ntf.msg) }} </small>
                      </div>

                  </a>
                  
                  <small style="width: 150px;" class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.time) }}</small>
                </li>
              </ul>
            </VuePerfectScrollbar>
          </vs-dropdown-menu>
        </vs-dropdown>
          
        <!-- CART DROPDOWN -->
        <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer ml-4 mr-4" @focus="changeNewOrdersToReaded()">
          <vs-button radius color="white" text-color="#666">
            <feather-icon icon="ShoppingCartIcon" class="cursor-pointer" :badge="unreadNewOrders"></feather-icon>
          </vs-button>
          
          <vs-dropdown-menu class="cart-dropdown vx-navbar-dropdown" :class="{'dropdown-custom': cartItems.length}">
            <!-- IF CART HAVE ITEMS: HEADER -->
            <template v-if="newOrders.length">
              <div class="notification-header text-center p-5 bg-primary text-white">
                <h3 class="text-white">{{ newOrders.length }} <span> {{ newOrders.length > 1 ? $t('Ordenes') : $t('Orden') }} </span> </h3>
                <p class="opacity-75"> {{ newOrders.length > 1 ? $t('Nuevos') : $t('Nuevo') }} </p>
              </div>
              
              <!-- CART ITEMS -->
              <VuePerfectScrollbar ref="mainSidebarPs" class="scroll-area--cart-items-dropdowm p-0 mb-10">
                <ul class="bordered-items">
                  <li v-for="item in newOrders" v-bind:key="item.id" @click="goToOrdersInProgress()" class="vx-row no-gutter cart-item cursor-pointer">
                    <!-- IMG COL -->
                    <div class="vx-col w-1/5 item-img-container bg-white flex items-center justify-center">
                      <img
                        v-if="item.order.order_items[0].image"
                        :src="item.order.order_items[0].image"
                        :alt="item.order.order_items[0].title"
                        class="cart-dropdown-item-img p-4"
                        />
                      <img
                        v-else
                        :src="require('@/assets/images/default-product.png')"
                        :alt="item.order.order_items[0].title"
                        class="cart-dropdown-item-img p-4"
                        />
                        
                    <!--   <img :src="item.order.order_items[0].image" :alt="item.order.order_items[0].title" class="cart-dropdown-item-img p-4">
                     -->
                    </div>
                    
                    <!-- INFO COL -->
                    <div class="vx-col w-4/5 pr-4 pl-2 py-4 flex flex-col justify-center">
                      <span class="font-medium block cart-item-title truncate">{{ item.order.order_items[0].title }} <span v-if="item.order.order_items.length > 1">y {{  item.order.order_items.length - 1 }} más...</span> </span>
                      
                      <!-- <small class="truncate mb-2">{{ item.time | datetime }}</small> -->
                      <!-- <small class="truncate mb-2">
                        {{ item.time[item.time.length - 1][Object.keys(item.time[item.time.length - 1])[0]] | datetime }}
                      </small>
                       -->
                      <div class="flex items-center justify-between">
                        <!-- <span class="text-sm font-medium">{{ item.quantity }} <small>x</small> ${{ item.price }}</span> -->
                        <span class="text-sm font-medium">{{ item.order.grandTotal | coin }}</span>
                        
                        <!-- <feather-icon icon="XIcon" svgClasses="h-4 w-4 cursor-pointer text-danger" class="hover:text-danger" @click.stop="removeItemFromCart(item)" /> -->
                      </div>
                    </div>
                  </li>
                </ul>
              </VuePerfectScrollbar>
              
              <div
                class="
                  checkout-footer
                  fixed
                  bottom-0
                  rounded-b-lg
                  text-primary
                  font-semibold
                  w-full
                  p-2
                  text-center
                  border
                  border-b-0
                  border-l-0
                  border-r-0
                  border-solid
                  d-theme-border-grey-light
                  cursor-pointer
                "
                @click="$router.push('/app/orders/in-progress').catch(() => {})"
              >
                <span class="flex items-center justify-center">
                  <feather-icon icon="TruckIcon" svgClasses="h-4 w-4"></feather-icon>
                  
                  <span class="ml-2"> {{ $t('Ver ordenes') }} </span>
                </span>
              </div>
            </template>
            
            <!-- IF CART IS EMPTY -->
            <template v-else>
              <p class="p-4">{{ $t('No hay nuevos pedidos') }}</p>
            </template>
          </vs-dropdown-menu>
        </vs-dropdown>
          
        <!-- USER META -->
        <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
          <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
            <vs-button radius color="white" text-color="#666">
              <feather-icon icon="UserIcon" class="cursor-pointer"></feather-icon>
            </vs-button>
            <feather-icon icon="ChevronDownIcon" class="cursor-pointer"></feather-icon>
            
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/app/profile').catch(() => {})"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2"> {{ $t('Mi Perfil') }} </span>
                </li>

                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/email').catch(() => {})"
                >
                  <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Inbox</span>
                </li> -->
              
                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/todo').catch(() => {})"
                >
                  <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Tasks</span>
                </li> -->
                
                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/chat').catch(() => {})"
                >
                  <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Chat</span>
                </li> -->
            
                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/apps/eCommerce/wish-list').catch(() => {})"
                >
                  <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">Wish List</span>
                </li> -->
            
                <vs-divider class="m-1"></vs-divider>
            
                <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="logout"
                >
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
                  <span class="ml-2"> <nobr> {{ $t('Cerrar sesión') }} </nobr> </span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue';

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable           from 'vuedraggable';
import Cookies             from 'js-cookie';
import i18n from '@/plugins/i18n';

export default {
  name: "the-navbar",
  props: {
      navbarColor: {
          type: String,
          default: "#fff",
      },
  },
  data() {
    return {
      navbarSearchAndPinList    : this.$store.state.navbarSearchAndPinList,
      searchQuery               : '',
      showFullSearch            : false,
      autoFocusSearch           : false,
      showBookmarkPagesDropdown : false,
      trackingNotificationFile  : require('@/assets/audio/notifications/new-order.mp3'),
      languaje_name: 'Portuguese',
      languaje_image: 'https://firebasestorage.googleapis.com/v0/b/sitidoctor-marketplace-dev.appspot.com/o/SAKURA%2FPNG%2Fflags%2Fpt.png?alt=media&token=dc556d8a-bacc-4dc1-8d53-a9ad076ac28d'
      
    }
  },

  created() {
    this.$store.dispatch( 'notification/getAllNotifications' );
    this.$store.dispatch( 'tracking/getAllNewOrders' );
  },

  watch: {
    '$route'() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    },
    unreadNewOrders( val ) {
      if( val > 0 ) {
        const audio = new Audio( this.trackingNotificationFile );
        audio.play();
      }
    },
  },

  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" ? "#10163a" : this.navbarColor
    },
    // HELPER
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default"
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced"
      else if (this.verticalNavMenuWidth) return "navbar-full"
    },
    // I18N
    getCurrentLocaleData() {
      const locale = this.$i18n.locale;
      if (locale == "en") return 'English' 
      if (locale == "pt") return 'Portuguese' 
      if (locale == "es") return 'Español'
      else return null
    },
    i18n_locale_img() {
      if (this.$i18n.locale == 'pt'){
        return require(`@/assets/images/flags/br.png`)
      }
      return require(`@/assets/images/flags/${this.$i18n.locale}.png`)
    },
    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPageIconColor() {
      if (this.navbarColor ==  '#fff' ) return false
      else if (this.navbarColor ==  'transparent' ) return false
      else return true
    },
    starredPagesLimited: {
      get() { return this.starredPages.slice(0, 10); },
      set(list) { this.$store.dispatch('arrangeStarredPagesLimited', list); }
    },
    starredPagesMore: {
      get() { return this.starredPages.slice(10); },
      set(list) { this.$store.dispatch('arrangeStarredPagesMore', list); }
    },
    // CART DROPDOWN
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },
    // PROFILE
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    user_displayName() {
      return this.activeUserInfo.displayName
    },
    activeUserImg() {
      return this.$store.state.AppActiveUser.photoURL;
    },
    pageTitle() {
      return this.$store.state.pageTitle;
    },

    notifications() {
      return this.$store.state.notification.notifications;
    },

    unreadNotifications() {
      const unreadNotifications = this.notifications.filter( ntf => ntf.unread === true );
      return unreadNotifications.length;
    },

    newOrders() {
      return this.$store.state.tracking.newOrders;
    },

    unreadNewOrders() {
      const unreadNewOrders = this.newOrders.filter( order => order.unread === true );
      return unreadNewOrders.length;
    },

    activeUser() {
      const userInfo = JSON.parse( Cookies.get( 'userInfo' ) );
      return !!userInfo;
    }

  },

  methods: {
    change_languaje(lang, src,locale){
      this.languaje_name = lang;
      this.languaje_image= src
      this.$i18n.locale = locale
    },
    updateLocale(locale, lang) {
      //this.$i18n.locale = locale;
      this.languaje_name = lang;
      i18n.locale = locale;
    },
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true);
    },
    selected(item) {
      this.$router.push(item.url).catch(() => {})
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch('updateStarredPage', { index: item.index, val: !item.highlightAction });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },

    elapsedTime(startTime) {
      //console.log(startTime)
      let date = new Date(startTime * 1000);
      
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()
      let hour = date.getHours()
      let minute = date.getMinutes()
      
      if(month < 10){
        const da = `${day}-0${month}-${year} ${hour}:${minute}`
        return da
      }else{
        const da = `${day}-${month}-${year} ${hour}:${minute}`
        return da
      }
      
    },

    logout() {
      this.$vs.loading();
      this.$store.dispatch( 'auth/logout' );
    },

    outside: function() {
      this.showBookmarkPagesDropdown = false
    },
    // CART DROPDOWN
    removeItemFromCart(item) {
      this.$store.dispatch('eCommerce/toggleItemInCart', item)
    },
    
    changeNotificationsToReaded() {
      this.$store.dispatch( 'notification/changeNotificationsToReaded' );
    },

    deleteNotifications() {
      this.$store.dispatch( 'notification/deleteAllNotifications' );
    },

    changeNewOrdersToReaded() {
      this.$store.dispatch( 'tracking/changeNewOrdersToReaded' );
    },

    goToOrdersInProgress() {
      window.location.href = '/app/orders/in-progress';
    },
  },

  directives: {
    'click-outside': {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = (e) => {
            if (bubble || (!el.contains(e.target) && el !== e.target)) {
                binding.value(e)
            }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind: function(el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  },

  components: {
    VxAutoSuggest,
    VuePerfectScrollbar,
    draggable
  },
}
</script>

<style lang="scss" scoped>
.vs-navbar-tr{
  -webkit-box-shadow: none;
  box-shadow: none;
}

</style>