<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Miguel Arenas
    Author URL: http://github.com/mxaxaxbx
========================================================================================== -->


<template functional>
    <footer class="the-footer flex-wrap justify-between mt-16" :class="classes">
        <span>
            &copy;
            {{ new Date().getFullYear() }} 
            <a target="_blank" href="https://sakura.foodchains.com.br" rel="follow">Sakura</a>
            <!-- All rights Reserved -->
        </span>
        <!-- <span class="md:flex hidden items-center">
            <span>Hand-crafted & Made with</span>
            <feather-icon icon="HeartIcon" svgClasses="stroke-current text-danger w-6 h-6" class="ml-2" />
        </span> -->
    </footer>
</template>

<script>
export default {
    name: "the-footer",
    props: {
        classes: {
            type: String,
        },
    }
}
</script>
